import { Col, Collapse, Row } from "antd";
import { getDownloadURL, ref } from "firebase/storage";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  Button,
  Checkbox,
  Dropdown,
  DropdownProps,
  Form,
  Image,
  Input,
  Label,
  Modal,
  Progress,
} from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";
import { storage } from "../../FireBaseConf";
import { convertImageUrl } from "../../helper/resize";
import Utils, { createKeywords } from "../../helper/utils";
import Food from "../../model/food";
import FirebaseFirestoreService from "../../services/FirebaseFirestoreService";
import { deleteFile, uploadFile } from "../../services/FirebaseStorageService";
import { colourStyles } from "./SelectStyleTree";
import { FOOD_CODE_LIMIT } from "../../helper/constants";

interface ModalFoodItemProps {
  id_restaurant: string;
  openModal: boolean;
  foodItem: Food;
  listCate: any[];
  setOpenModal: Function;
  loadFoodItem: () => void;
}

const ModalFoodItemUpdate: FC<ModalFoodItemProps> = (props) => {
  const { t } = useTranslation();

  const cateValueMapping = props.foodItem.category_id.map((item: any) => {
    return {
      value: item.value,
      label: props.listCate.find((ele) => ele.id === item.value)?.name,
    };
  });
  const [name, setName] = useState(props.foodItem.name);
  const [tag, setTag] = useState(props.foodItem.tag || "");
  const [foodCode, setFoodCode] = useState<string>(
    props.foodItem.code ? props.foodItem.code.toString() : ""
  );
  const [price, setPrice] = useState<string>(props.foodItem.price.toString());
  const [priceTax, setPriceTax] = useState<string>(
    props.foodItem.pricePostTax.toString()
  );
  const [secondPrice, setSecondPrice] = useState<string>(
    props.foodItem.price2 ? props.foodItem.price2.toString() : "0"
  );
  const [firstBlockTimeNet, setFirstBlockTimeNet] = useState<number>(
    props.foodItem.firstBlockTime ? props.foodItem.firstBlockTime : 0
  );
  const [secondBlockTimeNet, setSecondBlockTimeNet] = useState<number>(
    props.foodItem.secondBlockTime ? props.foodItem.secondBlockTime : 0
  );
  const [secondPriceTax, setSecondPriceTax] = useState<string>(
    props.foodItem.pricePostTax2 ? props.foodItem.pricePostTax2.toString() : "0"
  );
  const [desc, setDesc] = useState<string>(props.foodItem.description);
  const [printName, setPrintName] = useState<string>(
    props.foodItem?.printName || ""
  );
  const [taxRate, setTaxRate] = useState<string>("10");
  const [listCate, setListCate] = useState<any[]>([]);
  const [buffetListCate, setBuffetListCate] = useState<any[]>([]);
  const [isError, setErrorChange] = useState(false);
  const [value, setValue] = useState<any[]>(cateValueMapping);
  const [file, setFile] = useState<any>();
  const [percent, setPercent] = useState(0);
  const [imagePath, setImagePath] = useState<string>(props.foodItem.image);
  const [valueOptions, setValueOptions] = useState<any[]>(
    props.foodItem.options ?? []
  );
  const [valueTopping, setValueTopping] = useState<any[]>(
    props.foodItem.toppings ?? []
  );
  const [listOptions, setListOptions] = useState<any[]>([]);
  const [listTopping, setListTopping] = useState<any[]>([]);
  const [restaurant, setRestaurant] = useState<any>([]);
  const [languageValue, setLanguageValue] = useState<any>(
    props.foodItem.nameLanguages ? props.foodItem.nameLanguages : {}
  );
  const [taxSelection, taxSelectionOnchange] = useState<any[]>([
    {
      key: "10",
      text: "% 10",
      value: "10",
    },
  ]);
  const [isBlockTime, setIsBlockTime] = useState<any>(
    props.foodItem.isBlockTime ? props.foodItem.isBlockTime : false
  );
  const [isErrorFoodCode, setIsErrorFoodCode] = useState<boolean>(false);

  
  useEffect(() => {
    if (props.id_restaurant === "") {
      return;
    }
    const unsubscribe = FirebaseFirestoreService.streamCategoryListItems(
      props.id_restaurant,
      "",
      (querySnapshot: any) => {
        const updatedGroceryItems = querySnapshot.docs.map((doc: any) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBuffetListCate(updatedGroceryItems?.filter((item: any) => item.is_buffet));
      },
      (error: any) => {
        console.log(error);
      }
    );

    return unsubscribe;
  }, [props.id_restaurant, setBuffetListCate]);

  useEffect(() => {
    if (props.id_restaurant === "") {
      return;
    }

    const fecthCate = async () => {
      const unsubscribe = await FirebaseFirestoreService.getCateTree(
        props.id_restaurant
      );

      setListCate(unsubscribe);
    };

    const fetchRestaurantDetail = async () => {
      const res = await FirebaseFirestoreService.getRestaurantDetail(
        props.id_restaurant
      );
      setRestaurant(res.data());
    };

    fetchRestaurantDetail();
    fecthCate();
  }, [props.id_restaurant, setListCate, value]);

  useEffect(() => {
    if (props.id_restaurant === "") {
      return;
    }
    const unsubscribe = FirebaseFirestoreService.streamOptionsListItemsOnActive(
      props.id_restaurant,
      (querySnapshot: any) => {
        const updatedGroceryItems = querySnapshot.docs.map((doc: any) => ({
          label: doc.data().name,
          value: doc.id,
        }));
        setListOptions(updatedGroceryItems);
      },
      (error: any) => {}
    );
    return unsubscribe;
  }, [props.id_restaurant, setListOptions, valueOptions]);

  useEffect(() => {
    if (props.id_restaurant === "") {
      return;
    }
    const unsubscribe = FirebaseFirestoreService.streamToppingListItemsOnActive(
      props.id_restaurant,
      (querySnapshot: any) => {
        const updatedGroceryItems = querySnapshot.docs.map((doc: any) => ({
          label: doc.data().name,
          value: doc.id,
        }));
        setListTopping(updatedGroceryItems);
      },
      (error: any) => {}
    );
    return unsubscribe;
  }, [props.id_restaurant, setListTopping, valueTopping]);

  const handleChange = (newValue: any, data: any) => {
    const valueSelected = newValue.map((item: any) => {
      return {
        value: item.value,
      };
    });
    setValue(valueSelected);
  };

  const handleChangeOptions = (newValue: any, data: any) => {
    setValueOptions(newValue);
  };

  const handleChangeTopping = (newValue: any, data: any) => {
    setValueTopping(newValue);
  };

  const clearState = () => {
    props.setOpenModal(false);
  };

  const handleChangeUpload = (e: any) => {
    setImagePath("");
    setFile(e.target.files[0]);
  };

  const taxOnchange = (e: any, data: DropdownProps) => {
    e.preventDefault();
    const selected = data.value ? data.value.toString() : "";
    setPrice("");
    setPriceTax("");

    setTaxRate(selected);
  };

  const cateGoryQuery = () => {
    return value.map((item: any) => {
      return {
        value: item.value,
      };
    });
  };

  const onSubmit = async () => {
    setErrorChange(false);

    if (
      props.id_restaurant === "" ||
      name === "" ||
      price === "" ||
      priceTax == "" ||
      secondPrice == "" ||
      secondPriceTax == ""
    ) {
      setErrorChange(true);
      return;
    }

    if (Utils.validJapanese(tag) > 10) {
      setErrorChange(true);
      return;
    }
    const data = {
      name: name,
      description: desc,
      tag: tag,
      price: parseInt(price),
      price2: parseInt(secondPrice),
      pricePostTax: parseInt(priceTax),
      pricePostTax2: parseInt(secondPriceTax),
      isBlockTime: isBlockTime,
      firstBlockTime: firstBlockTimeNet,
      secondBlockTime: secondBlockTimeNet,
      image: imagePath,
      printName: printName,
      category_id: cateGoryQuery(),
      options: valueOptions,
      toppings: valueTopping,
      nameLanguages: languageValue,
    };

    const payloadFoodFilter = {
      id_food: props.foodItem.id,
      food_code: createKeywords(foodCode),
      id: props.foodItem.id,
      // check category is buffet
      is_buffet: buffetListCate?.map(item => item.id)?.includes(props.foodItem.category_id[0].value) ?? false,
    };

    const getFoodCode = await FirebaseFirestoreService.filterFoodCodeListItems(
      props.id_restaurant
    );
    const isExistedFoodCode = getFoodCode.some(
      (item) => item.food_code === foodCode
    );

    if (
      (props.foodItem.code !== foodCode && isExistedFoodCode) ||
      (foodCode.length < 3 && foodCode.length >= 1)
    ) {
      props.setOpenModal(true);
      return setIsErrorFoodCode(true);
    }

    await FirebaseFirestoreService.addFilterFoodCode(
      props.id_restaurant,
      payloadFoodFilter
    );

    if (file) {
      let path = `/${props.id_restaurant}/food/${uuidv4()}/${file.name}`;

      await uploadFile(file, path).on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
        },
        (err) => console.log(err),
        async () => {
          try {
            await deleteFile(props.foodItem.image);
          } catch (err) {
            console.log(err);
          }

          // download url
          let fullPath = await getDownloadURL(ref(storage, path)).then(
            (url: any) => {
              return url;
            }
          );
          data.image = convertImageUrl(fullPath);
          await FirebaseFirestoreService.updateFoodItem(
            props.id_restaurant,
            props.foodItem.id,
            data
          );

          props.loadFoodItem();
          clearState();
          return;
        }
      );
    } else {
      await FirebaseFirestoreService.updateFoodItem(
        props.id_restaurant,
        props.foodItem.id,
        data
      );
      props.loadFoodItem();
      clearState();
    }
  };

  return (
    <Modal
      onClose={() => {
        props.setOpenModal(false);
      }}
      closeOnDimmerClick={true}
      open={props.openModal}
      size="large"
      closeIcon={true}
    >
      <Modal.Header>{name}修正</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Field error={isError}>
              <label>{t("Food_Items.modal_item.update.name")}</label>
              <input
                placeholder={t("Food_Items.modal_item.update.placeholder.name")}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Field>
            <Form.Field error={isError}>
              <label>{t("Food_Items.table.print_name")}</label>
              <input
                placeholder={t("Food_Items.table.print_name")}
                value={printName}
                onChange={(e) => setPrintName(e.target.value)}
              />
            </Form.Field>
            <Form.Field error={isError}>
              <label>{t("Food_Items.modal_add.option")}</label>
              <Select
                isSearchable={false}
                isMulti={true}
                options={listOptions}
                defaultValue={valueOptions}
                placeholder={t("Food_Items.modal_add.placeholder.add_option")}
                onChange={handleChangeOptions}
              />
            </Form.Field>
            <Form.Field error={isError}>
              <label>{t("Food_Items.modal_add.topping")}</label>
              <Select
                isSearchable={false}
                isMulti={true}
                options={listTopping}
                defaultValue={valueTopping}
                placeholder={t("Food_Items.modal_add.placeholder.add_option")}
                onChange={handleChangeTopping}
              />
            </Form.Field>
          </Form.Group>

          <Form.Field>
            <label>{t("Food_Items.modal_add.block_time")}</label>
            <Checkbox
              toggle
              checked={isBlockTime}
              onChange={(e, data) => setIsBlockTime(data.checked)}
            />
          </Form.Field>
          {isBlockTime && (
            <Fragment>
              <Form.Group widths="equal">
                <Form.Field error={isError}>
                  <label>{t("Food_Items.modal_add.time_net")}</label>
                  <Input
                    labelPosition="right"
                    type="number"
                    placeholder="First Block Time"
                    onChange={(e) => {
                      setFirstBlockTimeNet(Number(e.target.value));
                    }}
                  >
                    <Label basic>Time</Label>
                    <input value={firstBlockTimeNet} />
                  </Input>
                </Form.Field>

                <Form.Field error={isError}>
                  <label>{t("Food_Items.modal_add.price")}</label>
                  <Input
                    labelPosition="right"
                    type="number"
                    placeholder={t("Food_Items.modal_add.placeholder.price")}
                    onChange={(e) => {
                      setPrice(e.target.value);
                      if (taxRate) {
                        setPriceTax(
                          Math.round(
                            Number(e.target.value) +
                              (Number(e.target.value) * Number(taxRate)) / 100
                          ).toString()
                        );
                      } else {
                        setPriceTax(e.target.value);
                      }
                    }}
                  >
                    <Label basic>{t("Food_Items.modal_add.unit")}</Label>

                    <input value={price} />
                  </Input>
                </Form.Field>
                <Form.Field error={isError}>
                  <label>{t("Food_Items.modal_add.tax_rate")}</label>
                  <Dropdown
                    placeholder="Select Tax Rate"
                    fluid
                    selection
                    defaultValue={taxSelection[0].value}
                    options={taxSelection}
                    onChange={taxOnchange}
                  />
                </Form.Field>
                <Form.Field error={isError}>
                  <label>{t("Food_Items.modal_add.post_tax")}</label>
                  <Input
                    labelPosition="right"
                    type="number"
                    placeholder={t(
                      "Food_Items.modal_item.update.placeholder.price"
                    )}
                    onChange={(e) => {
                      setPriceTax(e.target.value);
                      if (taxRate) {
                        setPrice(
                          Math.round(Number(e.target.value) / 1.1).toString()
                        );
                      } else {
                        setPrice(e.target.value);
                      }
                    }}
                  >
                    <Label basic>¥</Label>
                    <input value={priceTax} />
                  </Input>
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field error={isError}>
                  <label>{t("Food_Items.modal_add.time_ks")}</label>
                  <Input
                    labelPosition="right"
                    type="number"
                    placeholder="Second Block Time"
                    onChange={(e) => {
                      setSecondBlockTimeNet(Number(e.target.value));
                    }}
                  >
                    <Label basic>Time</Label>
                    <input value={secondBlockTimeNet} />
                  </Input>
                </Form.Field>

                <Form.Field error={isError}>
                  <label>{t("Food_Items.modal_add.price")}</label>
                  <Input
                    labelPosition="right"
                    type="number"
                    placeholder={t("Food_Items.modal_add.placeholder.price")}
                    onChange={(e) => {
                      setSecondPrice(e.target.value);
                      if (taxRate) {
                        setSecondPriceTax(
                          Math.round(
                            Number(e.target.value) +
                              (Number(e.target.value) * Number(taxRate)) / 100
                          ).toString()
                        );
                      } else {
                        setSecondPriceTax(e.target.value);
                      }
                    }}
                  >
                    <Label basic>{t("Food_Items.modal_add.unit")}</Label>

                    <input value={secondPrice} />
                  </Input>
                </Form.Field>
                <Form.Field error={isError}>
                  <label>{t("Food_Items.modal_add.tax_rate")}</label>
                  <Dropdown
                    placeholder="Select Tax Rate"
                    fluid
                    selection
                    defaultValue={taxSelection[0].value}
                    options={taxSelection}
                    onChange={taxOnchange}
                  />
                </Form.Field>
                <Form.Field error={isError}>
                  <label>{t("Food_Items.modal_add.post_tax")}</label>
                  <Input
                    labelPosition="right"
                    type="number"
                    placeholder={t(
                      "Food_Items.modal_item.update.placeholder.price"
                    )}
                    onChange={(e) => {
                      setSecondPriceTax(e.target.value);
                      if (taxRate) {
                        setSecondPrice(
                          Math.round(Number(e.target.value) / 1.1).toString()
                        );
                      } else {
                        setSecondPrice(e.target.value);
                      }
                    }}
                  >
                    <Label basic>¥</Label>
                    <input value={secondPriceTax} />
                  </Input>
                </Form.Field>
              </Form.Group>
            </Fragment>
          )}

          {isBlockTime === false && (
            <Form.Group widths="equal">
              <Form.Field error={isError}>
                <label>{t("Food_Items.modal_add.price")}</label>
                <Input
                  labelPosition="right"
                  type="number"
                  placeholder={t("Food_Items.modal_add.placeholder.price")}
                  onChange={(e) => {
                    setPrice(e.target.value);
                    if (taxRate) {
                      setPriceTax(
                        Math.round(
                          Number(e.target.value) +
                            (Number(e.target.value) * Number(taxRate)) / 100
                        ).toString()
                      );
                    } else {
                      setPriceTax(e.target.value);
                    }
                  }}
                >
                  <Label basic>{t("Food_Items.modal_add.unit")}</Label>

                  <input value={price} />
                </Input>
              </Form.Field>
              <Form.Field error={isError}>
                <label>{t("Food_Items.modal_add.tax_rate")}</label>
                <Dropdown
                  placeholder="Select Tax Rate"
                  fluid
                  selection
                  defaultValue={taxSelection[0].value}
                  options={taxSelection}
                  onChange={taxOnchange}
                />
              </Form.Field>
              <Form.Field error={isError}>
                <label>{t("Food_Items.modal_add.post_tax")}</label>
                <Input
                  labelPosition="right"
                  type="number"
                  placeholder={t(
                    "Food_Items.modal_item.update.placeholder.price"
                  )}
                  onChange={(e) => {
                    setPriceTax(e.target.value);
                    if (taxRate) {
                      setPrice(
                        Math.round(Number(e.target.value) / 1.1).toString()
                      );
                    } else {
                      setPrice(e.target.value);
                    }
                  }}
                >
                  <Label basic>¥</Label>
                  <input value={priceTax} />
                </Input>
              </Form.Field>
            </Form.Group>
          )}

          <Form.Group widths="equal">
            <Form.Field error={isError}>
              <label>{t("Food_Items.modal_item.update.tag")}</label>
              <input
                placeholder={t("Food_Items.modal_item.update.placeholder.tag")}
                value={tag}
                onChange={(e) => setTag(e.target.value)}
              />
            </Form.Field>
          </Form.Group>

          {restaurant?.allowSearch ? (
            <>
              <Form.Group widths="equal">
                <Form.Field error={isErrorFoodCode}>
                  <label>Code</label>
                  {/* <input
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    min="0"
                    type="number"
                    value={foodCode}
                    onChange={(e) => {
                      if (e.target.value.length > FOOD_CODE_LIMIT) return;

                      setFoodCode(e.target.value);
                    }}
                  /> */}

                  <Input
                    type="number"
                    placeholder="Code"
                    onChange={(e) => {
                      //TODO: bug auto change value
                      const inputVal = e.target.value;
                      if (inputVal.length <= FOOD_CODE_LIMIT) {
                        setFoodCode(inputVal);
                      }
                    }}
                  >
                    <input value={foodCode} />
                  </Input>
                </Form.Field>
              </Form.Group>

              <p
                style={{
                  display: `${isErrorFoodCode ? "block" : "none"}`,
                  color: "red",
                }}
              >
                {t("FoodEdit.error_exist")}
              </p>
            </>
          ) : (
            <></>
          )}

          <Form.Field>
            <label>{t("Food_Items.modal_item.update.description")}</label>
            <input
              placeholder={t(
                "Food_Items.modal_item.update.placeholder.description"
              )}
              maxLength={30}
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("Food_Items.modal_item.update.category")}</label>
              <Select
                isSearchable={false}
                isMulti={true}
                options={listCate}
                defaultValue={value}
                placeholder={t(
                  "Food_Items.modal_item.update.placeholder.category"
                )}
                onChange={handleChange}
                styles={colourStyles}
              />
            </Form.Field>
            <Form.Field error={isError}>
              <label>{t("Food_Items.modal_add.image")}</label>
              <input
                placeholder="Input here"
                accept="image/*"
                type={"file"}
                onChange={handleChangeUpload}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              {/* <label>Option</label>
                                <OptionFoodForm /> */}
            </Form.Field>
            <Form.Field>
              {imagePath && (
                <Image className="image-food-item" src={imagePath} />
              )}
              {file && (
                <Fragment>
                  <Image
                    className="image-food-item"
                    src={URL.createObjectURL(file)}
                  />
                  <Progress percent={percent} inverted progress />
                </Fragment>
              )}
            </Form.Field>
          </Form.Group>

          {restaurant?.listFoodLanguages?.length > 0 && (
            <Form.Field>
              <Collapse>
                <Collapse.Panel header={t("LanguageSetting.name")} key="1">
                  <Row gutter={[15, 15]}>
                    {restaurant.listFoodLanguages.map(
                      (item: any, index: number) => {
                        return (
                          <Col lg={12} sm={24}>
                            <label>{item.value}</label>
                            <input
                              placeholder="Tên"
                              value={
                                languageValue &&
                                languageValue[item.key] &&
                                languageValue[item.key].name
                              }
                              onChange={(e) => {
                                if (!languageValue[item.key]) {
                                  languageValue[item.key] = {
                                    name: "",
                                    description: "",
                                  };
                                }

                                if (!restaurant.listFoodLanguages[index]) {
                                  restaurant.listFoodLanguages[index] = {
                                    name: "",
                                    description: "",
                                  };
                                }
                                restaurant.listFoodLanguages[index].name =
                                  e.target.value;
                                languageValue[item.key].name = e.target.value;
                                setLanguageValue({ ...languageValue });
                              }}
                            />
                            <textarea
                              placeholder="Mô tả"
                              rows={2}
                              style={{ marginTop: 10 }}
                              value={
                                languageValue &&
                                languageValue[item.key] &&
                                languageValue[item.key].description
                              }
                              onChange={(e) => {
                                const value = { ...languageValue };
                                if (!value[item.key]) {
                                  value[item.key] = {
                                    name: "",
                                    description: "",
                                  };
                                }
                                restaurant.listFoodLanguages[
                                  index
                                ].description = e.target.value;
                                value[item.key].description = e.target.value;

                                setLanguageValue(value);
                              }}
                            />
                          </Col>
                        );
                      }
                    )}
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Form.Field>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={t("Food_Items.modal_item.update.btn_confirm")}
          labelPosition="right"
          icon="save"
          onClick={onSubmit}
          primary
        />
      </Modal.Actions>
    </Modal>
  );
};
export default ModalFoodItemUpdate;
