import React, { FC, Fragment, useEffect, useState } from "react";
import {
  Button,
  Image,
  Form,
  Modal,
  Input,
  Label,
  Progress,
  Dropdown,
  DropdownProps,
  Checkbox,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import FirebaseFirestoreService from "../../services/FirebaseFirestoreService";
import Select from "react-select";
import { uploadFile } from "../../services/FirebaseStorageService";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../FireBaseConf";
import { v4 as uuidv4 } from "uuid";
import { colourStyles } from "./SelectStyleTree";
import { FIRST_BLOCK_TIME_DEFAULT } from "../../helper/constants";
import { convertImageUrl } from "../../helper/resize";
import { Col, Collapse, Row } from "antd";

interface ModalFoodItemProps {
  id_restaurant: string;
  openModal: boolean;
  cateGoryDefault: any[];
  setOpenModal: Function;
}

const ModalFoodItem: FC<ModalFoodItemProps> = (props) => {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [price, setPrice] = useState<string>("0");
  const [secondPrice, setSecondPrice] = useState<string>("0");
  const [firstBlockTimeNet, setFirstBlockTimeNet] = useState<number>(FIRST_BLOCK_TIME_DEFAULT);
  const [secondBlockTimeNet, setSecondBlockTimeNet] = useState<number>(0);
  const [priceTax, setPriceTax] = useState<string>("0");
  const [secondPriceTax, setSecondPriceTax] = useState<string>("0");
  const [desc, setDesc] = useState<string>("");
  const [printName, setPrintName] = useState<string>("");
  const [isHandling, onChangeIsHandling] = useState<boolean>(false);
  const [taxRate, setTaxRate] = useState<string>("10");
  const [listCate, setListCate] = useState<any[]>([]);
  const [isError, setErrorChange] = useState(false);
  const [value, setValue] = useState<any[]>(props.cateGoryDefault);
  const [file, setFile] = useState<any>();
  const [percent, setPercent] = useState(0);
  const [listOptions, setListOptions] = useState<any[]>([]);
  const [listTopping, setListTopping] = useState<any[]>([]);
  const [valueOptions, setValueOptions] = useState<any[]>([]);
  const [valueTopping, setValueTopping] = useState<any[]>([]);
  const [restaurant, setRestaurant] = useState<any>([]);
  const [languageValue, setLanguageValue] = useState<any>({});
  const [tag, setTag] = useState("");
  const [taxSelection, taxSelectionOnchange] = useState<any[]>([
    {
      key: "10",
      text: "% 10",
      value: "10",
    },
  ]);
  const [isBlockTime, setIsBlockTime] = useState<any>(false);

  useEffect(() => {
    if (props.id_restaurant === "") {
      return;
    }
    const fecthCate = async () => {
      const unsubscribe = await FirebaseFirestoreService.getCateTree(props.id_restaurant);
      setListCate(unsubscribe);
    };

    const fetchRestaurantDetail = async () => {
      const res = await FirebaseFirestoreService.getRestaurantDetail(props.id_restaurant);
      setRestaurant(res.data());
    };

    fetchRestaurantDetail();
    fecthCate();
  }, [props.id_restaurant]);

  const taxOnchange = (e: any, data: DropdownProps) => {
    e.preventDefault();
    const selected = data.value ? data.value.toString() : "";
    onChangeIsHandling(false);

    setPrice("");
    setSecondPrice("");
    setPriceTax("");
    setSecondPriceTax("");
    setTaxRate(selected);
  };

  useEffect(() => {
    if (props.id_restaurant === "") {
      return;
    }
    const unsubscribe = FirebaseFirestoreService.streamOptionsListItemsOnActive(
      props.id_restaurant,
      (querySnapshot: any) => {
        const updatedGroceryItems = querySnapshot.docs.map((doc: any) => ({
          label: doc.data().name,
          value: doc.id,
        }));
        setListOptions(updatedGroceryItems);
      },
      (error: any) => {}
    );
    return unsubscribe;
  }, [props.id_restaurant, setListOptions]);

  useEffect(() => {
    if (props.id_restaurant === "") {
      return;
    }
    const unsubscribe = FirebaseFirestoreService.streamToppingListItemsOnActive(
      props.id_restaurant,
      (querySnapshot: any) => {
        const updatedGroceryItems = querySnapshot.docs.map((doc: any) => {
          return {
            label: doc.data().name,
            value: doc.id,
          };
        });
        setListTopping(updatedGroceryItems);
      },
      (error: any) => {}
    );
    return unsubscribe;
  }, [props.id_restaurant, setListTopping]);

  const handleChange = (newValue: any, data: any) => {
    onChangeIsHandling(false);
    const valueSelected = newValue.map((item: any) => {
      return {
        // label: item.label,
        value: item.value,
      };
    });
    setValue(valueSelected);
    setErrorChange(false);
    onChangeIsHandling(false);
  };

  const handleChangeOptions = (newValue: any, data: any) => {
    onChangeIsHandling(false);
    setValueOptions(newValue);
  };
  const handleChangeTopping = (newValue: any, data: any) => {
    onChangeIsHandling(false);
    setValueTopping(newValue);
  };

  const clearState = () => {
    setValue([]);
    setValueOptions([]);
    setName("");
    setPrice("");
    setSecondPrice("");
    setPriceTax("");
    setSecondPriceTax("");
    setDesc("");
    setFile(null);
    setPercent(0);
    setPrintName("");
    onChangeIsHandling(false);
    setIsBlockTime(false);
    setFirstBlockTimeNet(FIRST_BLOCK_TIME_DEFAULT);
    setSecondBlockTimeNet(0);
    props.setOpenModal(false);
  };

  const handleChangeUpload = (e: any) => {
    setFile(e.target.files[0]);
  };

  const cateGoryQuery = () => {
    return value.map((item: any) => {
      return {
        value: item.value,
      };
    });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    onChangeIsHandling(true);
    setErrorChange(false);
    console.log(value);

    if (
      props.id_restaurant === "" ||
      name === "" ||
      value.length === 0 ||
      price === "" ||
      priceTax == "" ||
      (isBlockTime && secondPrice === "") ||
      (isBlockTime && secondPriceTax === "")
    ) {
      setErrorChange(true);
      return;
    }

    const data = {
      name: name,
      tag: tag,
      description: desc,
      order: -1,
      price: parseInt(price),
      price2: parseInt(secondPrice),
      pricePostTax: parseInt(priceTax),
      pricePostTax2: parseInt(secondPriceTax),
      image: "",
      printName: printName,
      options: valueOptions,
      toppings: valueTopping,
      category_id: cateGoryQuery(),
      isBlockTime: isBlockTime,
      firstBlockTime: firstBlockTimeNet,
      secondBlockTime: secondBlockTimeNet,
      is_active: true,
      nameLanguages: languageValue,
    };
    if (file) {
      let path = `/${props.id_restaurant}/food/${uuidv4()}/${file.name}`;

      await uploadFile(file, path).on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setPercent(percent);
        },
        (err) => console.log(err),
        async () => {
          // download url
          let fullPath = await getDownloadURL(ref(storage, path)).then((url: any) => {
            return url;
          });
          setTimeout(async () => {
            data.image = convertImageUrl(fullPath);
            await FirebaseFirestoreService.addFood(props.id_restaurant, "", data);
            clearState();
          }, 4000);
          return;
        }
      );
    } else {
      await FirebaseFirestoreService.addFood(props.id_restaurant, "", data);

      clearState();
    }
  };

  return (
    <Fragment>
      <Modal
        onClose={() => {
          props.setOpenModal(false);
        }}
        closeOnDimmerClick={true}
        open={props.openModal}
        size="large"
        closeIcon={true}
      >
        <Modal.Header>{t("Food_Items.modal_add.header")}</Modal.Header>
        <Modal.Content scrolling>
          <Form>
            <Form.Group widths="equal">
              <Form.Field error={isError}>
                <label>{t("Food_Items.modal_add.name")}</label>
                <input
                  placeholder={t("Food_Items.modal_add.placeholder.name")}
                  value={name}
                  onChange={(e) => {
                    onChangeIsHandling(false);
                    setName(e.target.value);
                  }}
                />
              </Form.Field>
              <Form.Field error={isError}>
                <label>{t("Food_Items.table.print_name")}</label>
                <input
                  placeholder={t("Food_Items.table.print_name")}
                  value={printName}
                  onChange={(e) => {
                    onChangeIsHandling(false);
                    setPrintName(e.target.value);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>{t("Food_Items.modal_add.option")}</label>
                <Select
                  isSearchable={false}
                  isMulti={true}
                  options={listOptions}
                  defaultValue={valueOptions}
                  placeholder={t("Food_Items.modal_add.placeholder.add_option")}
                  onChange={handleChangeOptions}
                />
              </Form.Field>
              <Form.Field>
                <label>{t("Food_Items.modal_add.placeholder.topping")}</label>
                <Select
                  isSearchable={false}
                  isMulti={true}
                  options={listTopping}
                  defaultValue={valueTopping}
                  placeholder={t("Food_Items.modal_add.placeholder.add_option")}
                  onChange={handleChangeTopping}
                />
              </Form.Field>
            </Form.Group>

            <Form.Field>
              <label>{t("Food_Items.modal_add.block_time")}</label>
              <Checkbox toggle checked={isBlockTime} onChange={(e, data) => setIsBlockTime(data.checked)} />
            </Form.Field>
            {isBlockTime && (
              <Fragment>
                <Form.Group widths="equal">
                  <Form.Field error={isError}>
                    <label>{t("Food_Items.modal_add.time_net")}</label>
                    <Input
                      labelPosition="right"
                      type="number"
                      placeholder="First Block Time"
                      onChange={(e) => {
                        onChangeIsHandling(false);
                        setFirstBlockTimeNet(Number(e.target.value));
                      }}
                    >
                      <Label basic>Time</Label>
                      <input value={firstBlockTimeNet} />
                    </Input>
                  </Form.Field>

                  <Form.Field error={isError}>
                    <label>{t("Food_Items.modal_add.price")}</label>
                    <Input
                      labelPosition="right"
                      type="number"
                      placeholder={t("Food_Items.modal_add.placeholder.price")}
                      onChange={(e) => {
                        onChangeIsHandling(false);
                        setPrice(e.target.value);
                        if (taxRate) {
                          setPriceTax(
                            Math.round(
                              Number(e.target.value) + (Number(e.target.value) * Number(taxRate)) / 100
                            ).toString()
                          );
                        } else {
                          setPriceTax(e.target.value);
                        }
                      }}
                    >
                      <Label basic>{t("Food_Items.modal_add.unit")}</Label>

                      <input value={price} />
                    </Input>
                  </Form.Field>
                  <Form.Field error={isError}>
                    <label>{t("Food_Items.modal_add.tax_rate")}</label>
                    <Dropdown
                      placeholder="Select Tax Rate"
                      fluid
                      selection
                      defaultValue={taxSelection[0].value}
                      options={taxSelection}
                      onChange={taxOnchange}
                    />
                  </Form.Field>
                  <Form.Field error={isError}>
                    <label>{t("Food_Items.modal_add.post_tax")}</label>
                    <Input
                      labelPosition="right"
                      type="number"
                      placeholder={t("Food_Items.modal_item.update.placeholder.price")}
                      onChange={(e) => {
                        setPriceTax(e.target.value);
                        if (taxRate) {
                          setPrice(Math.round(Number(e.target.value) / 1.1).toString());
                        } else {
                          setPrice(e.target.value);
                        }
                      }}
                    >
                      <Label basic>¥</Label>
                      <input value={priceTax} />
                    </Input>
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field error={isError}>
                    <label>{t("Food_Items.modal_add.time_ks")}</label>
                    <Input
                      labelPosition="right"
                      type="number"
                      placeholder="Second Block Time"
                      onChange={(e) => {
                        setSecondBlockTimeNet(Number(e.target.value));
                      }}
                    >
                      <Label basic>Time</Label>
                      <input value={secondBlockTimeNet} />
                    </Input>
                  </Form.Field>

                  <Form.Field error={isError}>
                    <label>{t("Food_Items.modal_add.price")}</label>
                    <Input
                      labelPosition="right"
                      type="number"
                      placeholder={t("Food_Items.modal_add.placeholder.price")}
                      onChange={(e) => {
                        setSecondPrice(e.target.value);
                        if (taxRate) {
                          setSecondPriceTax(
                            Math.round(
                              Number(e.target.value) + (Number(e.target.value) * Number(taxRate)) / 100
                            ).toString()
                          );
                        } else {
                          setSecondPriceTax(e.target.value);
                        }
                      }}
                    >
                      <Label basic>{t("Food_Items.modal_add.unit")}</Label>

                      <input value={secondPrice} />
                    </Input>
                  </Form.Field>
                  <Form.Field error={isError}>
                    <label>{t("Food_Items.modal_add.tax_rate")}</label>
                    <Dropdown
                      placeholder="Select Tax Rate"
                      fluid
                      selection
                      defaultValue={taxSelection[0].value}
                      options={taxSelection}
                      onChange={taxOnchange}
                    />
                  </Form.Field>
                  <Form.Field error={isError}>
                    <label>{t("Food_Items.modal_add.post_tax")}</label>
                    <Input
                      labelPosition="right"
                      type="number"
                      placeholder={t("Food_Items.modal_item.update.placeholder.price")}
                      onChange={(e) => {
                        onChangeIsHandling(false);
                        setSecondPriceTax(e.target.value);
                        if (taxRate) {
                          setSecondPrice(Math.round(Number(e.target.value) / 1.1).toString());
                        } else {
                          setSecondPrice(e.target.value);
                        }
                      }}
                    >
                      <Label basic>¥</Label>
                      <input value={secondPriceTax} />
                    </Input>
                  </Form.Field>
                </Form.Group>
              </Fragment>
            )}

            {isBlockTime === false && (
              <Form.Group widths="equal">
                <Form.Field error={isError}>
                  <label>{t("Food_Items.modal_add.price")}</label>
                  <Input
                    labelPosition="right"
                    type="number"
                    placeholder={t("Food_Items.modal_add.placeholder.price")}
                    onChange={(e) => {
                      onChangeIsHandling(false);
                      setPrice(e.target.value);
                      if (taxRate) {
                        setPriceTax(
                          Math.round(
                            Number(e.target.value) + (Number(e.target.value) * Number(taxRate)) / 100
                          ).toString()
                        );
                      } else {
                        setPriceTax(e.target.value);
                      }
                    }}
                  >
                    <Label basic>{t("Food_Items.modal_add.unit")}</Label>

                    <input value={price} />
                  </Input>
                </Form.Field>
                <Form.Field error={isError}>
                  <label>{t("Food_Items.modal_add.tax_rate")}</label>
                  <Dropdown
                    placeholder="Select Tax Rate"
                    fluid
                    selection
                    defaultValue={taxSelection[0].value}
                    options={taxSelection}
                    onChange={taxOnchange}
                  />
                </Form.Field>
                <Form.Field error={isError}>
                  <label>{t("Food_Items.modal_add.post_tax")}</label>
                  <Input
                    labelPosition="right"
                    type="number"
                    placeholder={t("Food_Items.modal_item.update.placeholder.price")}
                    onChange={(e) => {
                      onChangeIsHandling(false);
                      setPriceTax(e.target.value);
                      if (taxRate) {
                        setPrice(Math.round(Number(e.target.value) / 1.1).toString());
                      } else {
                        setPrice(e.target.value);
                      }
                    }}
                  >
                    <Label basic>¥</Label>
                    <input value={priceTax} />
                  </Input>
                </Form.Field>
              </Form.Group>
            )}

            <Form.Group widths="equal">
              <Form.Field error={isError}>
                <label>{t("Food_Items.modal_item.update.tag")}</label>
                <input
                  placeholder={t("Food_Items.modal_item.update.placeholder.tag")}
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                />
              </Form.Field>
              <Form.Field></Form.Field>
            </Form.Group>

            <Form.Field>
              <label>{t("Food_Items.modal_add.description")}</label>
              <input
                placeholder={t("Food_Items.modal_add.placeholder.description")}
                maxLength={30}
                value={desc}
                onChange={(e) => {
                  onChangeIsHandling(false);
                  setDesc(e.target.value);
                }}
              />
            </Form.Field>
            <Form.Group widths="equal">
              <Form.Field error={isError}>
                <label>{t("Food_Items.modal_add.category")}</label>
                <Select
                  isMulti={true}
                  options={listCate}
                  defaultValue={value}
                  placeholder={t("Food_Items.modal_add.placeholder.category")}
                  onChange={handleChange}
                  styles={colourStyles}
                />
              </Form.Field>
              <Form.Field error={isError}>
                <label>{t("Food_Items.modal_add.image")}</label>
                <input placeholder="Input here" accept="image/*" type={"file"} onChange={handleChangeUpload} />
                {file && (
                  <Fragment>
                    <Image className="image-food-item" src={URL.createObjectURL(file)} />
                    <Progress percent={percent} inverted progress />
                  </Fragment>
                )}
              </Form.Field>
            </Form.Group>

            {restaurant?.listFoodLanguages?.length > 0 && (
              <Form.Field>
                <Collapse>
                  <Collapse.Panel header={t("LanguageSetting.name")} key="1">
                    <Row gutter={[15, 15]}>
                      {restaurant.listFoodLanguages.map((item: any, index: number) => {
                        return (
                          <Col sm={24}>
                            <label>{item.value}</label>
                            <input
                              placeholder="Tên"
                              value={languageValue && languageValue[item.key] && languageValue[item.key].name}
                              onChange={(e) => {
                                if (!languageValue[item.key]) {
                                  languageValue[item.key] = {
                                    name: "",
                                    description: "",
                                  };
                                }

                                if (!restaurant.listFoodLanguages[index]) {
                                  restaurant.listFoodLanguages[index] = {
                                    name: "",
                                    description: "",
                                  };
                                }
                                restaurant.listFoodLanguages[index].name = e.target.value;
                                languageValue[item.key].name = e.target.value;
                                setLanguageValue({ ...languageValue });
                              }}
                            />
                            <textarea
                              placeholder="Mô tả"
                              rows={2}
                              style={{ marginTop: 10 }}
                              value={languageValue && languageValue[item.key] && languageValue[item.key].description}
                              onChange={(e) => {
                                const value = { ...languageValue };
                                if (!value[item.key]) {
                                  value[item.key] = {
                                    name: "",
                                    description: "",
                                  };
                                }
                                restaurant.listFoodLanguages[index].description = e.target.value;
                                value[item.key].description = e.target.value;

                                setLanguageValue(value);
                              }}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </Form.Field>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={isHandling}
            content={t("Food_Items.modal_add.btn_confirm")}
            labelPosition="right"
            icon="save"
            onClick={onSubmit}
            primary
          />
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};
export default ModalFoodItem;
